import { getMediaUrlByType } from '@utils/helper';
import styles from '@styles/components/ItemRealEstate/ProjectRealEstate.module.scss';
import dynamic from 'next/dynamic';
import ButtonSave from '@components/ItemRealEstate/ButtonSave';
import React from 'react';
const IconLocation = dynamic(() => import('@public/svg/location.svg'), {
  ssr: false,
});

type Props = {
  wrapperStyle?: string,
  onPress?: Function,
  item?: Object,
};

export default function ProjectRealEstate({
  item,
  wrapperStyle,
  onPress = () => {},
}: Props) {
  const firstImage = item?.mediaUrls?.find((e) => e?.type === 'image') || {};
  const { projectName, fullAddress } = item;
  return (
    <div
      className={`${styles.projectRealEstate} ${wrapperStyle}`}
      onClick={() => {
        onPress();
      }}>
      <div className={styles.imgRealEstateProject}>
        <img
          style={{ width: '100%', height: '100%', borderRadius: 15 }}
          src={getMediaUrlByType(firstImage)}
          alt="img-real-estate-project"
        />
      </div>
      <div className={styles.containerInfoProject}>
        <div className={styles.innerContainer}>
          <a href="/404" className={styles.projectName}>
            {projectName?.toUpperCase()}
          </a>
          <div className={styles.infoProject}>
            <div className="px-4px lg:px-12px justify-start flex-col flex">
              <a className={styles.viewProject} href="/404">
                Xem ngay
              </a>
              <span className="flex flex-row items-center mt-12px">
                <div className={styles.priceKey}>Giá:</div>{' '}
                <div className={styles.priceValue}>Liên hệ</div>
              </span>
              <div className="flex-row flex mt-4px md:mt-12px items-end">
                <IconLocation className="w-14px h-18px mr-4px" />
                <div className={styles.fullAddress}>{fullAddress}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ButtonSave item={item} style={{ top: 8, right: 8 }} />
    </div>
  );
}
