import styles from '@styles/components/Home/Home.module.scss';
import TitleNavigation from '@components/titleNavigation';
import { useQuery } from '@apollo/client';
import { GET_REAL_ESTATE_PROJECT_BY_FIELD } from '@schemas/queries/realEstate';
import { fieldsGetRealEstateProject } from '@utils/configs/RealEstateConfiguarations/fieldGetRealEstateProject';
import chunk from 'lodash/chunk';
import dynamic from 'next/dynamic';
import ProjectRealEstateCarousel from './projectRealEstateCarousel';
import { useRouter } from 'next/router';
import { routeSearchList } from '@utils/configs/SearchList/const';
const BussinessNtrade = dynamic(
  () => import('@public/svg/businessNtrade.svg'),
  {
    ssr: false,
  },
);

const CarouselV2 = dynamic(() => import('@components/CarouselV2'), {
  ssr: false,
});

type Props = {
  wrapperClass?: string,
};

export default function OutstandingProject({ wrapperClass }: Props) {
  const { data: projectData } = useQuery(
      GET_REAL_ESTATE_PROJECT_BY_FIELD(fieldsGetRealEstateProject),
      { variables: { limit: 12 } },
    ),
    data = projectData?.getRealEstateProjects.items || [];
  const convertData = chunk(data, 3);
  const router = useRouter();
  return (
    <div
      className={`${styles.session} ${
        data?.length > 0 ? 'flex' : 'hidden'
      } ${wrapperClass}`}>
      <TitleNavigation
        title="Dự án nổi bật"
        Icon={BussinessNtrade}
        iconClassName="w-27px"
        showViewAll={data?.length > 0}
        onPressViewAll={() =>
          router.push(`/${routeSearchList}/project/allProjectType/allPostType`)
        }
      />
      <div className={`w-full ${styles.realEstateCarousel}`}>
        <CarouselV2
          breakpoints={{
            320: {
              slidesPerView: 1,
              slidesPerGroup: 1,
              spaceBetween: 9,
            },
            1024: {
              slidesPerView: 2,
              slidesPerGroup: 12,
              spaceBetween: 30,
            },
          }}
          showPagination
          data={convertData}
          RenderItem={({ index, item }) => (
            <ProjectRealEstateCarousel item={item} key={index} />
          )}
        />
      </div>
    </div>
  );
}
