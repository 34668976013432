import styles from '@styles/components/Home/Home.module.scss';
import ProjectRealEstate from '@components/ItemRealEstate/projectRealEstate';

type Props = {
  item?: Array,
};

export default function ProjectRealEstateCarousel(props: Props) {
  const { item = [] } = props;
  return (
    <div className={styles.projectRealEstateCarousel}>
      <div className="flex flex-1 flex-col lg:mr-12px mr-8px lg:mr-30px">
        <div className="flex flex-1 mb-8px lg:mb-12px">
          <ProjectRealEstate item={item?.[0]} />
        </div>
        <div className="flex flex-1 ">
          <ProjectRealEstate item={item?.[1]} />
        </div>
      </div>
      <div className="flex flex-1">
        <ProjectRealEstate item={item?.[2]} />
      </div>
    </div>
  );
}
