import dynamic from 'next/dynamic';
const MyDropdown = dynamic(() => import('@components/MyDropdown'));
import React, { useContext } from 'react';
import { AppContext, useAppContext } from '@utils/context/appContext';
const SaveRealEstate = dynamic(() => import('@components/SaveRealEstate'));

type Props = {
  item?: Object,
  wrapperClass?: string,
  style?: Object,
  container?: any,
};

export default function ButtonSave({
  item,
  wrapperClass,
  style = {},
  container,
}: Props) {
  const { setShowSaveSuccess } = useAppContext(),
    { isLogin } = useContext(AppContext);

  if (!isLogin) {
    return null;
  }

  return (
    <button
      className={`w-24px h-24px lg:w-39px left-4px lg:h-39px rounded-rd50 absolute ${wrapperClass}`}
      style={{ top: 40, ...style }}>
      <MyDropdown
        innerWrapperStyle={{ height: '100vh' }}
        container={container}
        disabled={!isLogin}
        DropdownComponent={SaveRealEstate}
        dropdownContext={{
          item,
          saveSuccess: () => {
            setShowSaveSuccess(true);
          },
        }}>
        <img
          alt="icon-save"
          src={item?.isSaved ? '/svg/active.svg' : '/svg/inactive.svg'}
          className="w-full h-full"
        />
      </MyDropdown>
    </button>
  );
}
